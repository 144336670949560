<template>
  <div class="container">
    <p class="d-inline-block mr-2 file-add-text"><strong>{{headerText}}</strong></p>
    <!-- #region File Input -->
    <button
      type="button"
      class="btn btn-icon btn-secondary"
      @click.prevent="$refs.fileInput.click()">
      <i class="fe fe-upload"></i>
    </button>
    <input
      ref="fileInput"
      hidden
      type="file"
      :accept="accept"
      @change="handleFileChange"
    >
    <p class="file-notice-text">{{noticeText}}</p>

    <!-- #endregion-->
    <!-- #region Selected Files -->
    <div v-if="selectedFile">
      <a
        :href="fileUrl"
        target="_blank"
        class="mr-4">{{selectedFile.name}}</a>
      <button
        type="button"
        class="btn btn-icon btn-outline-danger btn-del-photo"
        @click.prevent="removeFile(selectedFile)">
        <i class="fe fe-trash"></i>
      </button>
    </div>
    <div v-else class="col">
      <p class="no-file-text mt-4">{{noFileText}}</p>
    </div>
  </div>
  <!-- #endregion -->
</template>

<script>
export default {
  name: 'FileInput',
  props: {
    headerText: {
      type: String,
      default: 'Add File',
    },
    accept: {
      type: String,
    },
    noticeText: {
      type: String,
      default: '',
    },
    noFileText: {
      type: String,
      default: 'Please add a file',
    },
  },
  data() {
    return {
      selectedFile: null,
      fileUrl: null,
      loading: false,
    };
  },
  methods: {
    async handleFileChange(e) {
      try {
        if (e.target.files.length > 0) {
          const file = e.target.files[0];

          if (file.size > 10 * 1000000) { // 10 MB
            this.$emit('error', new Error('File too large. Files cannot exceed 10 MB.'));
            return;
          }

          this.selectedFile = file;
          this.fileUrl = URL.createObjectURL(file);
          this.$emit('fileChange', file);
        }
      }
      catch (ex) {
        this.$emit('error', ex);
      }
      finally {
        e.target.value = null;
      }
    },
    removeFile() {
      // empty the selectedFiles object
      if (window.confirm('Are you sure that you want to remove the file?')) {
        this.selectedFile = null;
        this.fileUrl = null;
        this.$emit('input', this.selectedFile);
      }
    },
  },
};
</script>

<style scoped>
.btn-del-file {
  width: 100%;
}

.no-file-text {
  text-align: center;
  font-size: 18px;
}

.file-notice-text {
  font-style: italic;
}

.file-add-text {
  font-size: 20px;
}
</style>
